import { FC, useCallback, useEffect, useState } from "react"
import styles from './PaymentDatesList.m.less'
import { EditDateModal } from "./EditDateModal"
import { DeletetDateModal } from "./DeleteDateModal"
import { Checkbox, CheckboxChangeEvent } from "../../../../../Checkbox"
import { Button } from "../../../../../button"
import { DeleteIcon } from "../../../../../Icons"
import { PaymentDateItem } from "../PaymenDateItem/PaymentDateItem"
import { getDatesCombinedByYears, getEmptyDatesByYears, getFlatDatesList, getYearFromDate } from "./utils"
import { Moment } from "moment"
import { VIEW_DATE_FORMAT } from "../../../../../constants"
import { Divider } from "../../../../../Divider"
import { TCouponPaymentDate } from "@dltru/dfa-models"

type TPaymentDatesListProps = {
  dates: TCouponPaymentDate[],
  isEdit: boolean,
  deleteDates: (dates: TCouponPaymentDate[]) => void
  editDate: (oldDate: TCouponPaymentDate, newDate: string) => void
  cancelEdit: () => void
}

const EmptyDatesBlock = () => <div className={styles.empty_dates_text}>Выплаты не запланированы</div>

export const PaymentDatesList: FC<TPaymentDatesListProps> = ({ 
  dates, 
  isEdit,
  editDate,
  deleteDates,
 }) => {
  const [checkedList, setCheckedList] = useState<Record<string, TCouponPaymentDate[]>>({});
  const [selectedDate, setSelectedDate] = useState<TCouponPaymentDate | undefined>()
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)

  useEffect(() => {
    setCheckedList(getEmptyDatesByYears(dates))
  }, [dates])

  useEffect(() => {
    if (!isEdit) {
      setCheckedList(getEmptyDatesByYears(dates))
    }
  }, [isEdit])

  const indeterminate = getFlatDatesList(checkedList).length > 0 && 
    Object.values(checkedList).flat().length < dates.length;

  const onEditClickHandler = useCallback((date: TCouponPaymentDate) => {
    setSelectedDate(date)
    setEditModalVisible(true)
  }, [])

  const onDeleteClickHandler = useCallback(() => {
    setDeleteModalVisible(true)
  }, [])

  const onChange = useCallback((e: CheckboxChangeEvent, date: TCouponPaymentDate) => {
    const year = getYearFromDate(date.payment_date)
    setCheckedList(prev => {
      let newDatesByYear = prev[year] || []
      if (e.target.checked) {
        newDatesByYear.push(date)
      } else {
        newDatesByYear = newDatesByYear.filter(prevDate => prevDate !== date)
      }
      return {
        ...prev,
        [year]: newDatesByYear
      }
    });
  }, [])

  const onCheckAllChange = useCallback((e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? getDatesCombinedByYears(dates) : getEmptyDatesByYears(dates))
  }, [dates])

  const closeEditModalHandler = useCallback(() => {
    setEditModalVisible(false)
  }, [])

  const closeDeleteModalHandler = useCallback(() => {
    setDeleteModalVisible(false)
  }, [])

  const handleEditDate = useCallback((date: Moment) => {
    if (selectedDate) {
      editDate(selectedDate, date.format(VIEW_DATE_FORMAT))
      setEditModalVisible(false)
    }
  }, [selectedDate])

  const handleDeleteDate = useCallback((date: TCouponPaymentDate) => {
    deleteDates([date])
  }, [])

  const handleDeleteDates = useCallback(() => {
    deleteDates(getFlatDatesList(checkedList))
    setDeleteModalVisible(false)
  }, [checkedList])

  return <>
    <div className={styles.dates_list_wrapper}>
      <div className={styles.dates_wrapper}>
        {!dates.length && <EmptyDatesBlock />}
        {Object.entries(getDatesCombinedByYears(dates)).map(([year, dates]) => (
          <div className={styles.dates_group_wrapper}>
            <span className={styles.year_subtitle}>{year}</span>
            {dates.map((date, i) => (
              <PaymentDateItem
                key={date.payment_date}
                isEdit={isEdit}
                onEdit={() => onEditClickHandler(date)}
                onDelete={() => handleDeleteDate(date)}
                name={`dateItem${i}`}
                onChange={(e) => onChange(e, date)}
                checked={Boolean(checkedList[year]?.find(d => d.payment_date === date.payment_date))}
              >
                {date.payment_date}
              </PaymentDateItem>
            ))}
          </div>
        ))}
      </div>
      {isEdit && <>
        <Divider />
        <div className={styles.dates_block_controls}>
          <Checkbox 
            name='checkAllDates'
            onChange={onCheckAllChange} 
            indeterminate={indeterminate} 
            checked={dates.length === getFlatDatesList(checkedList).length}
            disabled={!dates.length}
          >
            Выбрать все
          </Checkbox>
          <Button
            icon={<DeleteIcon className={styles.delete_btn_icon} />}
            type='link'
            size='small'
            className={styles.delete_btn}
            onClick={onDeleteClickHandler}
            disabled={!dates.length}
          >
            Удалить
          </Button>
        </div>
      </>}
    </div>
    <DeletetDateModal
      visible={isDeleteModalVisible}
      onCancel={closeDeleteModalHandler}
      handleDelete={handleDeleteDates}
    />
    {selectedDate &&
      <EditDateModal 
        visible={isEditModalVisible} 
        date={selectedDate.payment_date} 
        onCancel={closeEditModalHandler}
        handleEdit={handleEditDate}
      />
    }
  </>
} 