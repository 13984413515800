import { ModalProps, Space } from "antd";
import { FC } from "react";
import { Modal } from "../../../../components/Modal";
import { InfoCircleOutline } from "../../../../Icons";
import { getDatesCombinedByYears } from "./PaymentDatesList/utils";
import { PaymentDateItem } from "./PaymenDateItem/PaymentDateItem";
import styles from './PaymentDatesList/PaymentDatesList.m.less'
import { Button } from "../../../../button";
import { Alert } from "../../../../Alert";
import { Box } from "../../../../Box";
import { TCouponPaymentDate } from "@dltru/dfa-models";

interface IComponentProps extends ModalProps {
  dates: TCouponPaymentDate[]
  isPaidBeforeHoliday?: boolean
}

const getAlertText = (isBefore: boolean) => {
  let message = `Если дата выплаты выпадет на выходной или праздничный день, 
    то выплата купонного дохода будет произведена в ближайший рабочий день, `

  if (isBefore) {
    message += 'предшествующий плановой дате выплаты.'
  } else {
    message += 'следующий за плановой датой выплаты.'
  }

  return message
}

export const PaymentsScheduleModal: FC<IComponentProps> = (props) => {
  return <Modal
    {...props}
    title={
      <Space size={16}>
        <InfoCircleOutline className="auth-modal__gray-icon"/>
        График выплат купонного дохода
      </Space>
    }
    footer={<Button borderRadius={12} type='primary' onClick={props.onCancel}>Закрыть</Button>}
  >
    <Box>
      Выплаты купонного дохода запланированы на следующие даты:
    </Box>
    <Box margin={[30, 0]} className={styles.modal_inner}>
      {Object.entries(getDatesCombinedByYears(props.dates)).map(([year, dates]) => (
        <>
          <span className={styles.year_subtitle}>{year}</span>
          {dates.map(({ payment_date }) => (
            <PaymentDateItem
              key={payment_date}
              isEdit={false}
            >
              {payment_date}
            </PaymentDateItem>
          ))}
        </>
      ))}
    </Box>
    <Alert
      showIcon
      type="info"
      description={<p>{getAlertText(Boolean(props.isPaidBeforeHoliday))}</p>}
    />
  </Modal>

}