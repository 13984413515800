import { ECouponPaymentCollectType, ECouponPaymentMethod } from "@dltru/dfa-models"

export const radioOptions = [
    { value: true, label: 'предшествующий плановой дате выплаты' },
    { value: false, label: 'следующий за плановой датой выплаты' },
]

export const checkBoxLabel = 'Производить выплаты купонного дохода'
export const checkBoxLabelDisable = 'Не производить выплаты купонного дохода'

export const calculationMethodOptions = [
    {
        value: ECouponPaymentCollectType.account,
        label: 'С лицевого счёта Эмитента на лицевые счета Владельцев',
    },
]

const translateCouponPaymentMethod: Record<ECouponPaymentMethod, string> = {
  [ECouponPaymentMethod.FIXED]: 'Фиксированный',
  [ECouponPaymentMethod.DETERMINED]: 'Определяемый',
  [ECouponPaymentMethod.COMBINED]: 'Комбинированный'
} 

export const couponPaymentMethodOptions = Object.entries(translateCouponPaymentMethod)
    .map(([value, label]) => ({ value, label }))

export const alertDescription =
    'Фактический размер выплаты на единицу ЦФА указывается при осуществлении расчётов с владельцами в дату выплаты купонного дохода.'
