import { TCouponPaymentDate } from "@dltru/dfa-models"

export const getYearFromDate = (date: string) => date.split('.').slice(-1)[0]

export const getDatesCombinedByYears = (dates: TCouponPaymentDate[]) => {
  return dates.reduce((acc, cur) => {
    const year = getYearFromDate(cur.payment_date)
    const prevDates = acc[year] || []
    return {
      ...acc,
      [year]: [
        ...prevDates,
        cur
      ]
    }
  }, {} as Record<string, TCouponPaymentDate[]>)
}

export const getEmptyDatesByYears = (dates: TCouponPaymentDate[]) => {
  return dates.reduce((acc, cur) => {
    const year = getYearFromDate(cur.payment_date)
    return {
      ...acc,
      [year]: []
    }
  }, {} as Record<string, TCouponPaymentDate[]>)
}

export const getFlatDatesList = (dates: Record<string, TCouponPaymentDate[]>) => Object.values(dates).flat()
