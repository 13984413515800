import styles from './PaymentsSceduler.m.less'
import { PaymentDatesList } from './PaymentDatesList/PaymentDatesList'
import { FC, useCallback, useEffect, useState } from 'react'
import { AddDateModal } from './AddDateModal/AddDateModal'
import { CardHeader } from '../../../../card'
import { Button } from '../../../../button'
import cn from 'classnames'
import { TAddDates, TCouponPaymentDate, getSortedUniqDates } from '@dltru/dfa-models'
import _lodash from 'lodash'

type TPaymentsScedulerProps = {
  dates: TCouponPaymentDate[]
  addDates: (data: TAddDates) => void  
  saveAfterEditDates: (dates: TCouponPaymentDate[]) => void
}

export const PaymentsScheduler: FC<TPaymentsScedulerProps> = ({
  dates, 
  saveAfterEditDates,
  addDates,
}) => {
  const [editingDates, setEditingDates] = useState<TCouponPaymentDate[]>([])
  const [isAddDateModalVisible, setAddDateModalVisible] = useState(false)
  const [isEdit, setEdit] = useState(false)
  const title = isEdit ? 'Редактирование графика выплат' : 'График выплат' 

  useEffect(() => {
    setEditingDates(dates)
  }, [dates])
  
  const onEditClickHandler = useCallback(() => {
    setEdit(true)
  }, [])

  const onCancelEditHandler = useCallback(() => {
    setEditingDates(dates)
    setEdit(false)
  }, [dates])

  const onAddDateClickHandler = useCallback(() => {
    setAddDateModalVisible(true)
  }, [])

  const onAddDatesFinish = useCallback((data: TAddDates) => {
    addDates(data)
    setAddDateModalVisible(false)
  }, [])

  const cancelEdit = useCallback(() => {
    setEdit(false)
  }, [])

  const onDeleteDates = (dates: TCouponPaymentDate[]) => {
    setEditingDates(prev => _lodash.without(prev, ...dates))
  }

  const onEditDates = useCallback((oldDate: TCouponPaymentDate, newDate: string) => {
    setEditingDates(prev => 
      getSortedUniqDates(
        prev.map((date) => date.payment_date === oldDate.payment_date ? 
          { ...date, payment_date: newDate }: 
          date
        )
      )
    )
  }, [])

  const onSaveEditedDates = useCallback(() => {
    saveAfterEditDates(editingDates)
    setEdit(false)
  }, [editingDates])

  return <>
    <div className={cn(styles.payment_scheduler, (isEdit && styles.editing))}>
      <div className={styles.payment_scheduler_header}>
        <CardHeader title={title} padding={0}/>
        {!isEdit && 
          <div className={styles.payment_scheduler_header_controls}>
            <Button type="link" onClick={onAddDateClickHandler}>Добавить дату выплаты</Button>
            {dates.length > 0 && <Button type="link" onClick={onEditClickHandler}>Редактировать</Button>}
          </div>
        }
      </div>
      <div className={styles.payment_scheduler_inner}>
      {dates.length > 0 ? 
        <PaymentDatesList 
          dates={editingDates} 
          isEdit={isEdit} 
          deleteDates={onDeleteDates}
          editDate={onEditDates}
          cancelEdit={cancelEdit}
        /> : 
        <div className={styles.payment_scheduler_text}>Создайте индивидуальный график выплат</div>
      }
      {isEdit && dates.length > 0 && 
        <div className={styles.payment_scheduler_controls}>
          <Button
            borderRadius={12}
            size='middle'
            onClick={onCancelEditHandler}
          >
            Отмена
          </Button>
          <Button
            borderRadius={12}
            size='middle'
            type='primary'
            onClick={onSaveEditedDates}
          >
            Сохранить
          </Button>
        </div>
      }
      </div>
    </div>
    <AddDateModal
      visible={isAddDateModalVisible}
      onCancel={() => setAddDateModalVisible(false)}
      onFinish={onAddDatesFinish}
    />
  </>
}